import { template as template_2220cbc736d146e8b768e5a35df5e8ff } from "@ember/template-compiler";
const SidebarSectionMessage = template_2220cbc736d146e8b768e5a35df5e8ff(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_9c22f75507bb4e3098742ca5c67cf7da } from "@ember/template-compiler";
const FKText = template_9c22f75507bb4e3098742ca5c67cf7da(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_9827357e48d244e2a31972012b68d29f } from "@ember/template-compiler";
const FKControlMenuContainer = template_9827357e48d244e2a31972012b68d29f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
